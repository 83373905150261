import React from 'react';
import { colors } from '~/styles';

import './spinner.scss';
import useHref from '~/hooks/useHref';

const DEFAULT_COLOR = colors['stone-500'];
const RAC_COLOR = colors['red-500'];

type Props = {
    className?: string,
    color?: string,
    size?: number,
    useDefaultColor?: boolean,
    isCentered?: boolean,
}

export default function Spinner({ className, useDefaultColor = false, color = DEFAULT_COLOR, size = 50, isCentered }: Props): JSX.Element {
    const { isRoyalAnglianConnect } = useHref();

    const strokeColor = isRoyalAnglianConnect
        ? RAC_COLOR
        : useDefaultColor
            ? DEFAULT_COLOR
            : (color || 'currentColor');

    return (
        <span className={`${className} ${isCentered ? 'centered-spinner' : ''}`}>
            <svg
                className='spinner'
                fill='currentColor'
                width={`${size}px`}
                height={`${size}px`}
                viewBox='0 0 66 66'
                xmlns='http://www.w3.org/2000/svg'>
                <circle
                    className='path'
                    fill='none'
                    stroke={strokeColor}
                    strokeWidth='6'
                    strokeLinecap='round'
                    cx='33'
                    cy='33'
                    r='30' />
            </svg>
        </span>
    );
}
