import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useHref = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const app = (searchParams.get('app') || 'ma')?.toLowerCase();

    const [isMilitary, setIsMilitary] = useState(app === 'ma');
    const [isFusiliers, setIsFusiliers] = useState(app === 'fc');
    const [isRoyalAnglianConnect, setIsRoyalAnglianConnect] = useState(app === 'ra');

    useEffect(() => {
        if (app) {
            setIsMilitary(app === 'ma');
            setIsFusiliers(app === 'fc');
            setIsRoyalAnglianConnect(app === 'ra');
        } else {
            setIsMilitary(true);
        }
    }, [window.location]);

    return {
        isMilitary,
        isFusiliers,
        isRoyalAnglianConnect,
        app
    };
};

export default useHref;
